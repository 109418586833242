/*
 * @Description: 配置参数
 * @Author: 琢磨先生
 * @Date: 2022-05-09 21:46:47
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-07-19 09:31:41
 */

export default {
  /**
   * api请求host地址
   */
  HOST:
    process.env.NODE_ENV === "development"
      ? "http://localhost:6001"
      : "https://api.ts.itxiangtian.cn",
  /**
   * 本地缓存token名称
   */
  TOKEN: "token",

  /**
   * 应用编码
   */
  APP_CODE: "admin",
  /**
   * 项目app key
   */
  APP_KEY: "admin_pc",
  /**
   * 接口KEY
   */
  API_KEY: "admin_api",
  /**
   * API权限数据本地存储Key
   */
  API_STORE_KEY: "api_store_key",

  /**
   * 当前url地址对象
   */
  CURRENT_META_KEY:'current_meta_key',
};
